import React from 'react';
import Section from './section';
import aboutMeMainImage from './img/aboutMeMainImage.jpeg';
import './style/aboutme.css';
import { URL_ROOT } from '../common/RequestHelper';

class AboutMe extends Section {
  constructor(props) {
    super(props);
    this.state = {
      'aboutMe': {}
    }
  }

  componentDidMount() {
    fetch(URL_ROOT + 'latestAboutMe')
      .then(res => res.json())
      .then((result) =>
        this.setState({
          'aboutMe': result
        }));
  }

  getContents() {
    const aboutMe = this.state.aboutMe;
    return (
      <div id="aboutMe" className="section">
        <div id="mainImageHolder" className="floatRight">
          <img id="aboutMeMainImage" src={aboutMe.picture_location} alt={aboutMe.alt_text} />
        </div>
        <div id="aboutMeDescription" className="floatLeft">
          <h1>{aboutMe.title}</h1>
          <pre>{aboutMe.content}</pre>
        </div>
      </div>
    );
  }
}

export default AboutMe;