import React from 'react';

class Section extends React.Component {
  getContents() {
    return (
      <div className="section">
      </div>
   );
  }

  render() {
    return (
      <div className="sectionHolder" ref={this.props.scrollTo}>
        {this.getContents()}
      </div>
    );
  }
}

export default Section;