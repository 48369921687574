import Section from './section';
import homeBackground from './img/homeBackground.jpeg';
import './style/home.css'

class Home extends Section {
  getContents() {
    return (
      <div ref={this.props.homeRef} id="home" className="section">
        <div id="nameplateBackground">
          <img id="homeBackgroundImage" src={homeBackground} alt="Rain in Brazil" />
        </div>
        <div id="nameplate">
          <div id="name">Renata Martins</div>
          <div id="jobTitle">Business & Data Analyst</div>
          <div id="degreeTitle">MSBA, Syracuse University</div>
        </div>
      </div>
    );
  }
}

export default Home;