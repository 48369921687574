import React from 'react';
import Section from './section';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import './style/projects.css';
import { URL_ROOT } from '../common/RequestHelper';

var Rainbow = require('../../node_modules/rainbowvis.js');

class Projects extends Section {
  constructor(props) {
    super(props);
    this.state = {
      'projects': [],
      'displayProject': null,
    }
  }

  componentDidMount() {
    fetch(URL_ROOT + 'projects')
      .then(res => res.json())
      .then((result) =>
        this.setState({
          'projects': result['projects']
        }));
  }

  displayProject(index) {
    this.setState({
      'displayProject': this.state.projects[index]
    });
  }

  seeAllProjects() {
    this.setState({
      'displayProject': null,
    });
  }

  getProjectViews() {
    const projects = this.state.projects;
    if (projects.length === 0) {
      return [];
    }
    const numberOfItems = projects.length;
    const highestIndex = numberOfItems - 1;
    var rainbow = new Rainbow();
    rainbow.setNumberRange(0, highestIndex);
    rainbow.setSpectrum("lightblue", "blue");
    var projectViews = [];
    for (let i = 0; i < numberOfItems; i++) {
      var project = projects[i];
      console.log(i);
      projectViews = projectViews.concat([(
        <Project
          key={i}
          color={rainbow.colourAt(i)}
          imageUrl={project.picture_location}
          technologyUsed={project.technologies_used}
          projectDescription={project.small_description}
          onClick={() => this.displayProject(i)}
        />
      )]);
    }
    return projectViews;
  }

  getContents() {
    if (this.state.displayProject) {
      const displayProject = this.state.displayProject;
      return (
        <div id="projects" className="section">
          <div id="backArrow">
            <FontAwesomeIcon icon={faArrowLeft} id="seeAllProjectsButton" onClick={() => this.seeAllProjects()} />
          </div>
          <div id="displayProjectHeading">
            <div id="displayProjectName">{displayProject.name}</div>
            <div id="displayProjectTech">{displayProject.technologies_used}</div>
          </div>
          <div id="displayProjectHolder">
            <div>
              <div id="displayProjectPictureHolder">
                <img id="displayProjectPicture" src={displayProject.picture_location} alt="Preview of my project" />
              </div>
              <div id="displayProjectDescription">
                {displayProject.description}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      const projects = this.getProjectViews();
      return (
        <div id="projects" className="section">
          <div id="projectsTitle">Projects Spectrum:</div>
          <div id="projectsSubTitle">Try hovering over a color, click for more info!</div>
          <div id="projectHolder">
            {projects}
          </div>
        </div>
      );
    }
  }
}

class Project extends React.Component {
  render() {
    const imageUrl = this.props.imageUrl;
    const technologyUsed = this.props.technologyUsed;
    const projectDescription = this.props.projectDescription;
    return (
      <div id="projectBox" onClick={this.props.onClick} style={{backgroundColor: "#" + this.props.color}}>
        <div id="projectBoxHider">
          <div className="pictureTech">
            <img className="projectPicture" src={imageUrl} alt="Preview of my project" />
            <div className="projectTech">{technologyUsed}</div>
          </div>
          <div className="projectDescription">{projectDescription}</div>
        </div>
      </div>
    );
  }
}

export default Projects;