import React from 'react';
import Section from './section';
import { URL_ROOT } from '../common/RequestHelper';
import rexEmailThankYou from "./img/rexEmailThankYou.png";
import './style/contact.css';

class Contact extends Section {
  getContents() {
    return (
      <div id="contact" className="section">
        <div id="contactTitle">
          Send Me An Email!
        </div>
        <div>
          <Email />
        </div>
      </div>
    );
  }
}

class Email extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      'emailSent': false,
    }
  }

  sendEmail(event) {
    event.preventDefault();
    const form = event.target;
    const email = {
      name: form.name.value,
      email: form.email.value,
      subject: form.subject.value,
      message: form.message.value,
    }

    fetch(
      URL_ROOT + 'sendEmail/',
      {
        method: 'POST',
        body: JSON.stringify(email),
      })
    .then((result) =>
      this.setState({
        'emailSent': true
      })); 
  }

  render() {
    if (this.state.emailSent) {
      return (
        <div>
          <div id="emailThankYouHolder">
            <img id="rexEmailThankYou" src={rexEmailThankYou} alt="Rex Email Thank You" />
          </div>
          <div id="thankYouMessage">
            Thank you for reaching out, I will get back to you soon!
          </div>
        </div>
      );
    } else {
      return (
        <div id="email">
          <form onSubmit={(event) => this.sendEmail(event)}>
            <input name="name" className="emailInput" type="text" placeholder="Name" />
            <input name="email" className="emailInput" type="text" placeholder="Email" />
            <input name="subject" className="emailInput" type="text" placeholder="Subject" />
            <textarea name="message" className="emailInput" id="message" placeholder="Message" />
            <input id="submit" type="submit" />
          </form>
        </div>
      );
    }
  }
}

export default Contact;