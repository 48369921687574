import './style/App.css';
import Portfolio from './portfolio';

function App() {
  return (
    <div className="App minWidth center">
      <Portfolio />
    </div>
  );
}

export default App;
