import React from 'react';
import Menubar from './menubar';
import Home from './sections/home';
import AboutMe from './sections/aboutme';
import Projects from './sections/projects';
import Resume from './sections/resume';
import Contact from './sections/contact';
import MenuButton from './menubutton';
import './style/portfolio.css';

class Portfolio extends React.Component {
  constructor(props) {
    super(props);
    this.scroller = React.createRef();
    this.menubar = React.createRef();
    this.home = React.createRef();
    this.homeButton = new MenuButton("Renata Martins", () => this.scroller.current.scroll({top: 0, behavior: "smooth"}));
    const buttonLabels = this.getButtonLabels();
    this.sectionRefs = buttonLabels.map(label => React.createRef());
    this.menuButtons =
      buttonLabels.map((label, index) =>
        new MenuButton(
          label,
          () => this.scroll({scroller: this.scroller, to: this.sectionRefs[index], menubar: this.menubar})));
  }

  componentDidMount() {
    this.scroller.current.onscroll = () => {this.onscroll()};
  }

  onscroll() {
    var offset = this.menubar.current.offsetHeight + 250;
    this.sectionRefs.forEach((ref, index) => {
      let sectionStart = this.scroller.current.scrollTop + ref.current.getBoundingClientRect().top - offset;
      let sectionEnd = this.scroller.current.scrollTop + ref.current.getBoundingClientRect().bottom - offset;
      if (this.scroller.current.scrollTop >= sectionStart && this.scroller.current.scrollTop < sectionEnd) {
        this.menuButtons[index].ref.current.classList.add("activeMenuButton");
      } else {
        this.menuButtons[index].ref.current.classList.remove("activeMenuButton");
      }
    });

    var homeEnd = this.scroller.current.scrollTop + this.home.current.getBoundingClientRect().bottom - 50;
    let opacity = 1 - ((homeEnd - this.scroller.current.scrollTop) / 100);
    this.homeButton.ref.current.style.color = "rgb(255, 255, 255, " + opacity + ")";
    if (this.scroller.current.scrollTop > homeEnd - 100) {
      this.homeButton.ref.current.style.visibility = "visible";
    } else {
      this.homeButton.ref.current.style.visibility = "hidden";
    }
  }

  getButtonLabels() {
    return ["About Me", "Projects", "Resume", "Contact"];
  }

  scroll(options) {
    const scroller = options.scroller;
    const to = options.to;
    const offset = options.menubar ? options.menubar.current.offsetHeight : 0;
    const top = scroller.current.scrollTop + to.current.getBoundingClientRect().top - offset;
    scroller.current.scroll({top: top, behavior: "smooth"});
  }

  render() {
    return (
      <div id="portfolio">
        <Menubar menuButtons={this.menuButtons} menuRef={this.menubar} home={this.homeButton} />
        <div id="sections" ref={this.scroller}>
          <Home homeRef={this.home} />
          <AboutMe scrollTo={this.sectionRefs[0]}/>
          <Projects scrollTo={this.sectionRefs[1]} />
          <Resume scrollTo={this.sectionRefs[2]}/>
          <Contact scrollTo={this.sectionRefs[3]}/>
        </div>
      </div>
    );
  }
}

export default Portfolio;