import Section from './section';
import resumeImage from './img/resume.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons';
import resumeFile from './static/Renata_Martins_Resume.pdf';
import './style/resume.css';

class Resume extends Section {
  getContents() {
    return (
      <div id="resume" className="section">
        <div id="resumeImageHolder">
          <a href={resumeFile} download>
            <FontAwesomeIcon icon={faArrowAltCircleDown} id="resumeDownload" />
          </a>
          <img src={resumeImage} alt="My Resume" id="resumeImage" />
        </div>
      </div>
    );
  }
}

export default Resume;