import React from 'react';

class MenuButton {
  constructor(displayName, onClick) {
    this.displayName = displayName;
    this.onClick = onClick;
    this.ref = React.createRef();
  }
}

export default MenuButton;