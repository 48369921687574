import React from 'react';
import Github from './img/github-logo-white.png';
import './style/menubar.css';

class Menubar extends React.Component {
  render() {
    const menuButtons = this.props.menuButtons.map((button, index) => 
      <div key={index} ref={button.ref} className="menuButton" onClick={button.onClick}>{button.displayName}</div>);
    const home = this.props.home;
    return (
      <div id="menubar" ref={this.props.menuRef} className="minWidth">
        <div id="homeButton" key="home" ref={home.ref} className="menuButton" onClick={home.onClick}>{home.displayName}</div>
        {menuButtons}
        <a href="https://github.com/rmarti38"><div id="socialMediaTab" className="menuButton"><img id="socialMediaImg" src={Github} alt="Github Logo" /></div></a>
      </div>
    );
  }
}

export default Menubar;