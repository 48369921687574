let backendHost;
let cors;
const apiVersion = 'v1';

const hostname = window && window.location && window.location.hostname;

if (hostname === 'www.renatamartins.me') {
  backendHost = 'https://www.renatamartins.me/';
  cors = 'cors';
} else if (hostname === 'renatas-portfolio.herokuapp.com') {
  backendHost = 'https://renatas-portfolio.herokuapp.com/'
} else {
  backendHost = process.env.REACT_APP_BACKEND_HOST || 'http://localhost:8000/';
}

export const URL_ROOT = `${backendHost}`;
export const CORS = cors;